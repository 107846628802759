$subMenuWidth: 250px;
#desktopMenu {
	.sub-arrow {
		display: none !important;
	}
	.col-count-1 {
		width: $subMenuWidth * 1 !important;
		max-width: 999px !important;
	}
	.col-count-2 {
		width: $subMenuWidth * 2 !important;
		max-width: 999px !important;
	}
	.col-count-3 {
		width: $subMenuWidth * 3 !important;
		max-width: 999px !important;
	}
	a.current,
	a.highlighted {
		color: $nav-current-color !important;
		background: $nav-current-background !important;
	}
	> li {
		position: relative;
		> a {
			font-family: $navbar-font-family;
			font-size: $nav-font-size;
			font-weight: $nav-font-weight;
			font-style: $nav-font-style;
			line-height: $nav-line-height;
			letter-spacing: $nav-letter-spacing;
			color: $nav-color;
			text-transform: $nav-text-transform;
			margin: $nav-margin !important;
			padding: $nav-padding !important;
			display: flex;
			align-items: center;
			justify-content: space-between;
			&:hover,
			&.bg-light {
				background: $nav-hover-background !important;
				color: $nav-hover-color !important;
			}

			&[aria-expanded="true"] {
				color: $nav-current-color !important;
				background: $nav-current-background !important;
			}
			&.btn {
				padding-left: 20px !important;
				padding-right: 20px !important;
				opacity: 1;
				color: #fff !important;
				transition: opacity 0.2s ease;
				background: $primary !important;
				border-color: $primary !important;
				&[aria-expanded="true"] {
					color: #fff !important;
					border-color: $primary !important;
					background: $primary !important;
				}
				&:hover {
					opacity: 0.85;
				}
			}
		}
		.dropdown-container {
			transform: rotateX(30deg) translate3d(0, 5px, 0);
			transition: 0.4s ease;
			opacity: 0;
			visibility: hidden;
		}
		&.show .dropdown-container {
			transform: rotateX(0) translate3d(0, 0, 0);
			opacity: 1;
			visibility: visible;
		}
		.dropdown-element {
			display: block;
			position: absolute;
			top: -0.75rem;
			height: 1rem;
			width: 1rem;
			left: 2rem;
			z-index: -1;
			transition: 0.4s ease;
			&:before {
				content: "";
				display: block;
				height: 1rem;
				width: 1rem;
				transform: rotate(45deg) translate3d(50%, 50%, 0);
				background-color: $nav-dropdown-background;
				border-radius: 0 !important;
				z-index: -1;
				transition: 0.4s ease;
			}
		}

		.dropdown-menu {
			background-color: transparent;
			border: none;
			box-shadow: none;
			padding: $nav-dropdown-container-margin !important;

			&.traditional-dropdown {
				background-color: $nav-dropdown-background;
				border-radius: 0 !important;
				border: $nav-dropdown-border;
				box-shadow: $nav-dropdown-box-shadow;
				padding: $nav-dropdown-container-padding-y
					$nav-dropdown-container-padding-x !important;
			}

			.container-fluid {
				background-color: $nav-dropdown-background;
				border-radius: 0 !important;
				border: $nav-dropdown-border;
				box-shadow: $nav-dropdown-box-shadow;
				padding: 0 !important;
			}
			.row {
				> .col {
					padding: $nav-dropdown-container-padding-y
						$nav-dropdown-container-padding-x !important;
					&:not(:first-child) {
						border-left: 1px solid $border-color;
					}
				}
			}
			a {
				font-family: $font-family-base !important;
				font-size: $nav-dropdown-font-size !important;
				font-weight: $nav-dropdown-font-weight !important;
				line-height: $nav-dropdown-line-height !important;
				padding: $nav-dropdown-item-padding-y $nav-dropdown-item-padding-x !important;
				letter-spacing: $nav-dropdown-letter-spacing !important;
				text-transform: $nav-dropdown-text-transform !important;
				font-style: $nav-dropdown-font-style !important;
				color: $nav-dropdown-second-color !important;
				&.third-level {
					padding-left: $nav-dropdown-item-padding-x * 1.75 !important;
					color: $nav-dropdown-third-color !important;
					font-weight: 500 !important;
				}
				&:hover,
				&.bg-light {
					background: $nav-dropdown-hover-background !important;
					color: $nav-dropdown-hover-color !important;
				}
				&.current {
					&:not(.more) {
						background: #63543f !important;
						color: #fff !important;
					}
				}
			}
		}
	}
	&[class*="collapsible"] {
		> li {
			.dropdown-menu {
				background: transparent !important;
				width: 100% !important;
				min-width: 1px !important;
				max-width: 9999px !important;
				box-shadow: none !important;
				padding: 0 0 0 10px !important;
				margin: 0 !important;
				> li:last-child {
					padding-bottom: 10px;
				}
				a {
					font-size: $nav-font-size;
					font-weight: $nav-font-weight;
					font-style: $nav-font-style;
					line-height: $nav-line-height;
					letter-spacing: $nav-letter-spacing;
					color: $nav-color;
					text-transform: $nav-text-transform;
					margin: $nav-margin;
					padding: $nav-padding;
					&:hover,
					&.bg-light {
						background: $nav-hover-background !important;
						color: $nav-hover-color !important;
					}
					&.current {
						background: $mobile-current-background !important;
						color: $mobile-current-color !important;
					}
				}
			}
		}
	}
}
#desktopMenu.navbar-expand-xl {
	@include media-breakpoint-down(lg) {
		.navbar-collapse {
			position: fixed;
			z-index: 9999;
			top: 0;
			right: 0;
			bottom: 0;
			left: auto;
			height: 100% !important;
			width: 80%;
			max-width: 400px;
			transition: 0.4s ease;
			opacity: 0;
			visibility: hidden;
			transform: translate3d(50px, 0, 0);
			display: block !important;
			&.show {
				opacity: 1;
				visibility: visible;
				transform: translate3d(0, 0, 0);
			}
			.container {
				overflow-y: auto;
			}
			ul.navbar-nav {
				a {
					font-size: $mobile-nav-font-size !important;
					line-height: $mobile-nav-line-height !important;
					padding: $mobile-nav-padding !important;
				}
			}
		}
	}
}
#desktopMenu.navbar-expand-lg {
	@include media-breakpoint-down(md) {
		.navbar-collapse {
			position: fixed;
			z-index: 9999;
			top: 0;
			right: 0;
			bottom: 0;
			left: auto;
			height: 100% !important;
			width: 80%;
			max-width: 400px;
			transition: 0.4s ease;
			opacity: 0;
			visibility: hidden;
			transform: translate3d(50px, 0, 0);
			display: block !important;
			&.show {
				opacity: 1;
				visibility: visible;
				transform: translate3d(0, 0, 0);
			}
			.container {
				overflow-y: auto;
			}
			ul.navbar-nav {
				a {
					font-size: $mobile-nav-font-size !important;
					line-height: $mobile-nav-line-height !important;
					padding: $mobile-nav-padding !important;
				}
			}
		}
	}
}
#desktopMenu.navbar-expand-md {
	@include media-breakpoint-down(sm) {
		.navbar-collapse {
			position: fixed;
			z-index: 9999;
			top: 0;
			right: 0;
			bottom: 0;
			left: auto;
			height: 100% !important;
			width: 80%;
			max-width: 400px;
			transition: 0.4s ease;
			opacity: 0;
			visibility: hidden;
			transform: translate3d(50px, 0, 0);
			display: block !important;
			&.show {
				opacity: 1;
				visibility: visible;
				transform: translate3d(0, 0, 0);
			}
			.container {
				overflow-y: auto;
			}
			ul.navbar-nav {
				a {
					font-size: $mobile-nav-font-size !important;
					line-height: $mobile-nav-line-height !important;
					padding: $mobile-nav-padding !important;
				}
			}
		}
	}
}
#desktopMenu.navbar-expand-sm {
	@include media-breakpoint-down(xs) {
		.navbar-collapse {
			position: fixed;
			z-index: 9999;
			top: 0;
			right: 0;
			bottom: 0;
			left: auto;
			height: 100% !important;
			width: 80%;
			max-width: 400px;
			transition: 0.4s ease;
			opacity: 0;
			visibility: hidden;
			transform: translate3d(50px, 0, 0);
			display: block !important;
			&.show {
				opacity: 1;
				visibility: visible;
				transform: translate3d(0, 0, 0);
			}
			.container {
				overflow-y: auto;
			}
			ul.navbar-nav {
				a {
					font-size: $mobile-nav-font-size !important;
					line-height: $mobile-nav-line-height !important;
					padding: $mobile-nav-padding !important;
				}
			}
		}
	}
}
