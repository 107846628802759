/*
This is where you write custom SASS
*/

.slide-out-from-right .current {
    background-color: transparent !important;
    font-weight: bold;
}

#searchBackDrop {
	transition: 0.3s ease;
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0.6);
	visibility: hidden;
}
body.search-show #searchBackDrop {
	opacity: 1;
	visibility: visible;
}

section.collage-section {
	.h1 {
		@include media-breakpoint-down(lg) {
			font-size: 48px !important;
		}
	}
}
.btn.extra-small {
	padding-top: 10px;
	padding-bottom: 10px;
}

section.services-section {
	.btn {
		&:hover {
			background-color: rgb(252, 176, 23) !important;
			border-color: rgb(252, 176, 23) !important;
			color: $black !important;
		}
	}
	.service {
		padding: 10% 5%;
		transition: 0.3s ease;
		border-radius: 5px;
		text-align: center;

		h5 {
			font-family: 'Open Sans', sans-serif;
			font-size: 20px;
			text-transform: uppercase;
		}
		@include media-breakpoint-down(md) {
			& {
				padding: 3% 15%;
			}
		}
		@include media-breakpoint-down(xs) {
			& {
				padding: 3% 5%;
			}
		}
	}
	a {
		color: #fff !important;
	}
	.box-link {
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: block;
		border: 1px solid transparent;
		-webkit-transition: all .5s ease-in-out;
  		transition: all .5s ease-in-out;
		border-radius: 4px;

        &:hover {
            border: 5px solid #fff;
        }
    }
}

#ajax-loading-screen, #ajax-loading-screen[data-effect="center_mask_reveal"] span {
    background-color: #63543f
}
#ajax-loading-screen .reveal-1 {
    background-color: #9f8d74;
}
#ajax-loading-screen .reveal-2 {
    background-color: #63543f;
}
.portfolio-loading, #ajax-loading-screen .loading-icon, .loading-icon, .pp_loaderIcon {
    background-image: url("");
}

/*-------------------------------------------------------------------------*/
/* 3. Page Transitions
/*-------------------------------------------------------------------------*/

#ajax-loading-screen{
	background-color:#fff;
	width:100%;
	height:100%;
	position:fixed;
	top:0;
	left:0;
	display:none;
	z-index:1000000000
  }
  #ajax-loading-screen .reveal-1, 
  #ajax-loading-screen .reveal-2{
	position:absolute;
	left:100%;
	top:0;
	width:100%;
	height:100%
  }
  #ajax-loading-screen[data-effect*="horizontal_swipe"]{
	background-color:transparent!important;
	left:-100%
  }
  body[data-ajax-transitions="true"] #ajax-loading-screen[data-method="standard"][data-effect*="horizontal_swipe"]{
	display:block
  }
  body[data-ajax-transitions="true"][data-apte="horizontal_swipe_basic"] #ajax-loading-screen .reveal-2 {
	display: none;
  }
  #ajax-loading-screen.in-from-right{
	left:0;
  }
  .no-cssanimations #ajax-loading-screen.loaded .reveal-1, 
  .no-cssanimations #ajax-loading-screen.loaded .reveal-2{
	display:none
  }
  #ajax-loading-screen.loaded .reveal-1{
	backface-visibility: hidden;
	-webkit-animation:nectar-anim-effect-2-2 1.85s cubic-bezier(0.67,0,0.3,1) forwards;
	animation:nectar-anim-effect-2-2 1.85s cubic-bezier(0.67,0,0.3,1) forwards
  }
  #ajax-loading-screen.loaded .reveal-2{
	backface-visibility: hidden;
	-webkit-animation:nectar-anim-effect-2-1 1.85s cubic-bezier(0.67,0,0.3,1) forwards;
	animation:nectar-anim-effect-2-1 1.85s cubic-bezier(0.67,0,0.3,1) forwards
  }
  #ajax-loading-screen.loaded.in-from-right .reveal-1{
	-webkit-animation:nectar-anim-effect-2-1 1.85s cubic-bezier(0.67,0,0.3,1) forwards;
	animation:nectar-anim-effect-2-1 1.85s cubic-bezier(0.67,0,0.3,1) forwards
  }
  body[data-apte="horizontal_swipe_basic"] #ajax-loading-screen.loaded.in-from-right .reveal-1{
	-webkit-animation:nectar-anim-effect-2-1 1.1s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
	animation:nectar-anim-effect-2-1 1.1s cubic-bezier(0.215, 0.61, 0.355, 1) forwards 
  }
  #ajax-loading-screen.loaded.in-from-right .reveal-2{
	-webkit-animation:nectar-anim-effect-2-2 1.85s cubic-bezier(0.67,0,0.3,1) forwards;
	animation:nectar-anim-effect-2-2 1.85s cubic-bezier(0.67,0,0.3,1) forwards
  }
  body[data-ajax-transitions="true"] #ajax-loading-screen[data-effect*="horizontal_swipe"].hidden{
	display:none
  }
  body[data-ajax-transitions="true"] #ajax-loading-screen[data-effect*="horizontal_swipe"].hidden.loaded.in-from-right {
	display: block;
  }
  @-webkit-keyframes nectar-anim-effect-2-1{
	0%{
	  -ms-transform:translateX(0);
	  -webkit-transform:translate3d(0,0,0);
	  transform:translate3d(0,0,0)
	}
	30%, 100%{
	  -ms-transform:translateX(-100%);
	  -webkit-transform:translate3d(-100%,0,0);
	  transform:translate3d(-100%,0,0);
	  -webkit-animation-timing-function:cubic-bezier(0.67,0,0.3,1);
	  animation-timing-function:cubic-bezier(0.67,0,0.3,1)
	}
  }
  @keyframes nectar-anim-effect-2-1{
	0%{
	  -ms-transform:translateX(0);
	  -webkit-transform:translate3d(0,0,0);
	  transform:translate3d(0,0,0)
	}
	30%, 100%{
	  -ms-transform:translateX(-100%);
	  -webkit-transform:translate3d(-100%,0,0);
	  transform:translate3d(-100%,0,0);
	  -webkit-animation-timing-function:cubic-bezier(0.67,0,0.3,1);
	  animation-timing-function:cubic-bezier(0.67,0,0.3,1)
	}
  }
  @-webkit-keyframes nectar-anim-effect-2-2{
	0%,14.5%{
	  -ms-transform:translateX(0);
	  -webkit-transform:translate3d(0,0,0);
	  transform:translate3d(0,0,0)
	}
	34.5%, 100%{
	  -ms-transform:translateX(-100%);
	  -webkit-transform:translate3d(-100%,0,0);
	  transform:translate3d(-100%,0,0);
	  -webkit-animation-timing-function:cubic-bezier(0.67,0,0.3,1);
	  animation-timing-function:cubic-bezier(0.67,0,0.3,1)
	}
  }
  @keyframes nectar-anim-effect-2-2{
	0%,14.5%{
	  -ms-transform:translate3d(0,0,0);
	  -webkit-transform:translate3d(0,0,0);
	  transform:translate3d(0,0,0)
	}
	34.5%, 100%{
	  -ms-transform:translate3d(-100%,0,0);
	  -webkit-transform:translate3d(-100%,0,0);
	  transform:translate3d(-100%,0,0);
	  -webkit-animation-timing-function:cubic-bezier(0.67,0,0.3,1);
	  animation-timing-function:cubic-bezier(0.67,0,0.3,1)
	}
  }
  body[data-ajax-transitions="true"] #ajax-loading-screen[data-method="standard"], 
  body[data-ajax-transitions="true"] #ajax-loading-screen[data-effect*="horizontal_swipe"][data-method="ajax"], 
  body[data-ajax-transitions="true"] #ajax-loading-screen[data-method="standard"] .loading-icon{
	display:block;
	opacity:1
  }
  body #ajax-loading-screen[data-effect="center_mask_reveal"]{
	background-color:transparent
  }
  body[data-ajax-transitions="true"] #ajax-loading-screen[data-effect="center_mask_reveal"].hidden{
	display:none
  }
  #ajax-loading-screen[data-effect="center_mask_reveal"] span{
	position:absolute;
	background:#fff;
	z-index:100;
	-webkit-transition:0.8s cubic-bezier(0.12,0.75,0.4,1);
	transition:0.8s cubic-bezier(0.12,0.75,0.4,1) 
  }
  #ajax-loading-screen .mask-top{
	top:0;
	left:0;
	height:50%;
	width:100%
  }
  #ajax-loading-screen .mask-right{
	top:0;
	right:0;
	height:100%;
	width:50%
  }
  #ajax-loading-screen .mask-bottom{
	bottom:0;
	right:0;
	height:50%;
	width:100%
  }
  #ajax-loading-screen .mask-left{
	top:0;
	left:0;
	height:100%;
	width:50%
  }
  #ajax-loading-screen.loaded .mask-top{
	-webkit-transform:translateY(-100%) translateZ(0);
	-ms-transform:translateY(-100%) translateZ(0);
	transform:translateY(-100%) translateZ(0)
  }
  #ajax-loading-screen.loaded .mask-right{
	-webkit-transform:translateX(100%) translateZ(0);
	-ms-transform:translateX(100%) translateZ(0);
	transform:translateX(100%) translateZ(0)
  }
  #ajax-loading-screen.loaded .mask-bottom{
	-webkit-transform:translateY(100%) translateZ(0);
	-ms-transform:translateY(100%) translateZ(0);
	transform:translateY(100%) translateZ(0)
  }
  #ajax-loading-screen.loaded .mask-left{
	-webkit-transform:translateX(-100%) translateZ(0);
	-ms-transform:translateX(-100%) translateZ(0);
	transform:translateX(-100%) translateZ(0)
  }
  #ajax-loading-screen[data-effect="center_mask_reveal"].set-to-fade span, 
  #ajax-loading-screen[data-effect="center_mask_reveal"].set-to-fade.loaded span {
	width:100%;
	height:100%;
	top:0;
	left:0;
	-webkit-transform:none;
	  transform:none; 
  }
  @media only screen and (max-device-width: 2600px) {
	body[data-ajax-transitions="true"].using-mobile-browser #ajax-loading-screen[data-method="standard"][data-disable-mobile="1"] {
		display: none;
	  }
  }

  @media only screen and (min-width : 1px) and (max-width : 999px) {
	body[data-ajax-transitions="true"] #ajax-loading-screen[data-method="standard"][data-disable-mobile="1"] {
		display: none;
	  }
  }
  

body.page-type-3 {
	.card {
		margin-left: 200px;

		@media screen and (max-width: 1199px) {
			margin-left: 100px;
		}

		@media screen and (max-width: 991px) {
			margin-left: 0px;
		}
	}
	.page-content {
		> .container {
			max-width: 1700px;
			margin: 0 auto;
			padding: 0 50px !important;

			@media screen and (max-width: 1199px) {
				padding: 0 15px !important;
			}
		}
	}
}
.py-8px {
	padding-top: 8px;
	padding-bottom: 8px;
}
.sticky-header {
	transition: 0.3s ease;
}
.hover-fx {
	box-shadow: none;
	transform: translateY(0);
	transition: 0.3s ease;
	&:hover {
		box-shadow: 0 20px 38px rgba(0, 0, 0, 0.16) !important;
		transform: translateY(-3px);
	}
}

@media (max-width: 1200px) {
	.display-1 {
		font-size: calc(1.1rem + 3.5vw);
	}
}

@keyframes spinning_animation {
	0% {
		transform: scale(1) rotate(0deg);
	}
	50% {
		transform: scale(0.8) rotate(360deg);
	}
	100% {
		transform: scale(1) rotate(720deg);
	}
}

// body[data-ajax-transitions="true"]
// 	#ajax-loading-screen[data-method="standard"]
// 	.loading-icon {
// 	display: block;
// 	opacity: 1;
// 	transition: 0.3s ease;
// }

// #ajax-loading-screen {
// 	background-color: #fff;
// 	width: 100%;
// 	height: 100%;
// 	position: fixed;
// 	transition: 0.3s ease;
// 	top: 0;
// 	left: 0;
// 	display: none;
// 	z-index: 1000000000;
// }

.loading-icon.spin,
.portfolio-loading.spin,
.pp_loaderIcon.spin,
.default-loading-icon.spin {
	animation: 2s cubic-bezier(0.63, 0.045, 0.34, 1) 0s normal none
		spinning_animation;
	-webkit-animation: 2s cubic-bezier(0.63, 0.045, 0.34, 1) 0s normal none
		spinning_animation;
	animation-iteration-count: 1;
}

// #ajax-loading-screen .loading-icon {
// 	background-repeat: no-repeat;
// 	background-position: center center;
// 	display: block;
// 	position: absolute;
// 	opacity: 0;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// }

body.search-show {
	.search-ui {
		transform: translate3d(0, 0vh, 0);
		-webkit-transform: translate3d(0, 0vh, 0);
		&:before {
			background-color: #ffffff;
		}
	}
}

.search-ui [id*="_SearchForm"].input-group:before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: -10px;
	height: 5px;
	z-index: 1000;
	width: 100%;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background: #DAD7CD;
}

.search-ui {
	[id*="_SearchForm"].input-group {
		max-width: 1200px;
		margin: 0 auto;
		padding: 0;
		line-height: 100px !important;
		~ span {
			max-width: 1200px;
			margin: 0 auto;
			text-align: left;
			display: inline-block;
			margin-top: 40px;
			width: 100%;
		}
	}
	background-color: #fff;
	&:before {
		content: "";
		transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		display: block;
		background-color: #fff;
		-ms-transform: scaleY(0);
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
		-ms-transform-origin: top;
		-webkit-transform-origin: top;
		transform-origin: top;
	}

	height: 35vh;
	position: absolute;
	transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1),
		opacity 0.8s cubic-bezier(0.2, 1, 0.3, 1);
	transform: translate3d(0, -35vh, 0);
	-webkit-transform: translate3d(0, -35vh, 0);
	z-index: 2000 !important;
	padding: 0;
	top: -1px;
}

header {
	.search-toggle {
		color: #999 !important;
		position: absolute;
		top: 50%;
		transform: translate3d(0, -50%, 0);
		z-index: 301;
		right: 30px;
		&:hover {
			color: #63543f !important;
		}
	}

	&.scrolling.hide {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
}

#quickLinks {
	div.socials {
		a {
			&:hover {
				color: #222222;
			}
		}
	}
	div.links {
		a {
			&:before {
				content: "";
				position: absolute;
				bottom: -3px;
				height: 1px;
				transition: 0.35s ease;
				background: $primary;
				left: 0;
				right: 100%;
			}

			&:hover {
				color: rgba(0, 0, 0, 0.8) !important;
				&:before {
					right: 0;
				}
			}
		}
	}
}

section.welcome-section {
	.btn {
		&:hover {
			background-color: $primary;
			border-color: $primary;
			opacity: 0.87;
			color: #fff;
		}
	}
	p.h3 {
		font-family: $display-font-family !important;
	}
	@media only screen and (min-width: 1425px) {
		[class*="col"] {
			padding: calc(1245px * 0.04);
		}

		@media only screen and (min-width: 1000px) {
			[class*="col"] {
				padding: calc((100vw - 180px) * 0.04);
			}
		}
		[class*="col"] {
			padding: calc(600px * 0.06);
		}
	}
}

section.services-section {
	padding-top: calc(100vw * 0.07);
	padding-bottom: calc(100vw * 0.03);
	@include media-breakpoint-down(md) {
		[class*="col"]:nth-of-type(3n + 1) div.d-flex {
			align-items: flex-end !important;
		}

		[class*="col"]:nth-of-type(3n + 3) div.d-flex {
			align-items: flex-start !important;
		}
	}
	@include media-breakpoint-down(sm) {
		[class*="col"]:nth-of-type(2n + 1) div.d-flex {
			align-items: flex-end !important;
		}

		[class*="col"]:nth-of-type(2n + 2) div.d-flex {
			align-items: flex-start !important;
		}
		span {
			font-size: 14px !important;
		}
		.d-flex.align-items-center.justify-content-center.flex-column
			> div.text-center {
			width: 190px !important;
		}
	}
	@include media-breakpoint-down(xs) {
		[class*="col"]:nth-of-type(2n + 1) div.d-flex {
			align-items: flex-end !important;
		}

		[class*="col"]:nth-of-type(2n + 2) div.d-flex {
			align-items: flex-start !important;
		}
		span {
			font-size: 14px !important;
		}

		.d-flex.align-items-center.justify-content-center.flex-column
			> div.text-center {
			width: 150px !important;
		}
	}
	a.rounded-circle {
		width: 110px;
		height: 110px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 2px solid $primary;
		background: $white;
		&:hover {
			background: $primary;
			i {
				color: $white !important;
			}
		}
	}
	i {
		font-size: 50px;
	}
}

section.team-section {
	[id*="_name"] span.d-block:not(:first-of-type) {
		letter-spacing: 2px;
	}

	padding-top: 0px !important;
	padding-bottom: calc(100vw * 0.06);
	.team-line {
		width: 10px;
		background: $primary;
		height: 2px;
		left: 50%;
		transform: translateX(-50%) !important;
		width: 40px;
		position: absolute;
		content: "";
	}
	.owl-item {
		width: 395px !important;
		height: 420px !important;

		.item-text {
			padding: 0 20px;
			left: 50%;
			transform: translateX(-50%);
			bottom: 20px;
		}
	}
	.flickity-viewport {
		margin: 80px 0px;
		overflow: visible;
	}
	.carousel-cell {
		width: 395px !important;
		height: 420px !important;
		margin-right: 20px;
		.item-text {
			padding: 0 20px;
			left: 50%;
			transform: translateX(-50%);
			bottom: 20px;
		}
	}
	.flickity-page-dots,
	.previous,
	.next {
		display: none;
	}
}

section.emergency-section {
	.font-size-25px {
		font-size: 25px !important;
	}
	@include media-breakpoint-down(lg) {
		.font-size-25px {
			font-size: 20px !important;
		}
	}

	[style*="max-width: 430px"] > span {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	[style*="max-width: 430px"] > span:before,
	[style*="max-width: 430px"] > span:after {
		content: "";
		position: absolute;
		background-color: white;
		transition: 0.5s ease;
	}

	[style*="max-width: 430px"] > span:first-of-type:before {
		top: 0;
		left: 0;
		right: 100%;
		height: 1px;
	}
	[style*="max-width: 430px"] > span:first-of-type:after {
		bottom: 0;
		left: 100%;
		right: 0;
		height: 1px;
	}

	[style*="max-width: 430px"] > span:last-of-type:before {
		bottom: 0;
		left: 0;
		height: 0px;
		width: 1px;
	}
	[style*="max-width: 430px"] > span:last-of-type:after {
		bottom: 0;
		right: 0;
		height: 0px;
		width: 1px;
	}

	&.visible [style*="max-width: 430px"] > span:first-of-type:before {
		right: 0;
	}
	&.visible [style*="max-width: 430px"] > span:first-of-type:after {
		left: 0;
	}

	&.visible [style*="max-width: 430px"] > span:last-of-type:before {
		height: 100%;
	}
	&.visible [style*="max-width: 430px"] > span:last-of-type:after {
		height: 100%;
	}

	&.visible {
		.emergency-center-line {
			width: 20%;
		}
	}
	.emergency-center-line {
		width: 0%;
		transition: 0.3s ease;
		background-color: rgba(255, 255, 255, 0.6);
		height: 4px;
		left: 0;
		position: relative;
	}

	i {
		color: #1e6692;
		font-size: 16px;
	}
	padding: calc(1245px * 0.05);

	[style*="max-width: 430px"] {
		padding: calc(600px * 0.06);
	}

	@media only screen and (min-width: 1425px) {
		& {
			padding: calc(1245px * 0.05);
		}
		[style*="max-width: 430px"] {
			padding: calc(1245px * 0.05);
		}
	}
	@media only screen and (min-width: 1000px) {
		& {
			padding: calc((100vw - 180px) * 0.05);
		}
		[style*="max-width: 430px"] {
			padding: calc((100vw - 180px) * 0.05);
		}
	}
	@include media-breakpoint-down(md) {
		[style*="max-width: 430px"] {
			max-width: 100% !important;
			margin-top: 120px;
			padding: calc(600px * 0.03);
		}
		& {
			padding: 40px 30px !important;
		}
	}
	.btn {
		padding: 13px 35px;
		border: 2px solid rgba(255, 255, 255, 0.75);
		&:hover {
			background-color: #1e6692;
			border-color: #1e6692;
		}
	}
}

#desktopMenu>li .dropdown-menu a.current:not(.more) {
    border-radius: 0 !important;
	background-color: #63543f !important;
}
#desktopMenu>li .dropdown-menu a:hover {
	border-radius: 0 !important;
}

section.care-credit-section {
	.btn {
		padding: 6px 14px;
		font-size: 12px;
		border: 2px solid rgba(255, 255, 255, 0.75);
		&.care-credit:hover {
			background-color: #09755c;
			color: $white !important;
			border: 2px solid rgba(255, 255, 255, 1);
		}
		&.scratch-pay:hover {
			background-color: $primary !important;
			color: $white !important;
		}
	}

	[class*="col"] {
		padding: calc(100vw * 0.04);

		@media only screen and (min-width: 1425px) {
			& {
				padding: calc(1245px * 0.04);
			}
		}

		@media only screen and (min-width: 1000px) {
			& {
				padding: calc((100vw - 180px) * 0.04);
			}
		}
	}
}

section.grooming-section {
	.btn {
		padding: 10px 24px;
		font-size: 12px;
	}

	[style*="width: 40%"],
	.col {
		& {
			padding: calc(100vw * 0.04);
		}
	}

	@include media-breakpoint-down(md) {
		[style*="width: 40%"] {
			width: 100% !important;
		}
		& {
			height: auto !important;
		}
		[class*="col"] {
			padding-left: 30px !important;
			padding-right: 30px !important;
		}
	}
	@include media-breakpoint-down(xs) {
		[class*="col"] {
			padding-left: 20px !important;
			padding-right: 20px !important;
		}
		.tagline-wrap {
			padding-left: 10px !important;
			padding-right: 10px !important;
		}
	}
}

section.testimonials {
	.testimonials-line {
		background: $primary;
		height: 2px;
		width: 80px;
	}

	@include media-breakpoint-down(sm) {
		[style*="max-width: 70%"] {
			max-width: none !important;
		}
		.testimonial-next-prev .prev,
		.testimonial-next-prev .next {
			transform: none !important;
			top: auto !important;
			margin-top: 0 !important;
		}
	}

	ul {
		display: inline-block;
		text-align: center;
		margin-left: 0;
	}

	.controls ul {
		margin: 0px;
		width: 100%;
	}

	.testimonial-next-prev .prev {
		position: absolute;
		top: 50%;
		height: 40px;
		width: 40px;
		font-size: 28px;
		-ms-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		margin-top: -40px;
		left: 7.5%;
		margin-left: -8px;
		text-align: center;
		z-index: 1;
	}
	.testimonial-next-prev .next {
		position: absolute;
		top: 50%;
		height: 40px;
		line-height: 40px;
		margin-top: -40px;
		font-size: 28px;
		width: 40px;
		margin-right: -8px;
		-ms-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		right: 7.5%;
		z-index: 1;
		text-align: center;
	}
	.testimonial-next-prev .next:before,
	.testimonial-next-prev .prev:before {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
	}
	.testimonial-next-prev .next:after,
	.testimonial-next-prev .prev:after {
		backface-visibility: hidden;
		display: block;
		content: " ";
		z-index: 100;
		position: absolute;
		width: 22px;
		height: 2px;
		background-color: #000;
		top: 48%;
		opacity: 0;
		right: 11px;
		cursor: pointer;
		-ms-transform: translateY(-50%) scaleX(0) translateZ(0);
		transform: translateY(-50%) scaleX(0) translateZ(0);
		-webkit-transform: translateY(-50%) scaleX(0) translateZ(0);
		transition: opacity 0.5s cubic-bezier(0.2, 1, 0.2, 1),
			transform 0.5s cubic-bezier(0.2, 1, 0.2, 1);
		-webkit-transition: opacity 0.5s cubic-bezier(0.2, 1, 0.2, 1),
			-webkit-transform 0.5s cubic-bezier(0.2, 1, 0.2, 1);
	}
	.testimonial-next-prev .next:after {
		right: 8px;
	}
	.testimonial-next-prev .next:hover:after,
	.testimonial-next-prev .prev:hover:after {
		opacity: 1;
		-ms-transform: translateY(-50%) scaleX(1) translateZ(0);
		transform: translateY(-50%) scaleX(1) translateZ(0);
		-webkit-transform: translateY(-50%) scaleX(1) translateZ(0);
	}
	.testimonial-next-prev .next:hover:before {
		-ms-transform: translateX(10px);
		-webkit-transform: translateX(10px);
		transform: translateX(10px);
	}
	.testimonial-next-prev .prev:hover:before {
		-ms-transform: translateX(-10px);
		-webkit-transform: translateX(-10px);
		transform: translateX(-10px);
	}
	.testimonial-next-prev svg {
		position: absolute;
		left: -2px;
		top: -2px;
	}
	.testimonial-next-prev .prev:before {
		left: -1px;
		position: relative;
	}
	.testimonial-next-prev .next:before {
		right: -1px;
		position: relative;
	}
	.testimonial-next-prev .prev:before,
	.testimonial-next-prev .next:before {
		line-height: 40px;
		color: #000;
		-ms--webkit-transition: -webkit-transform 0.5s cubic-bezier(0.2, 1, 0.2, 1);
		transition: transform 0.5s cubic-bezier(0.2, 1, 0.2, 1);
		-webkit-transition: -webkit-transform 0.5s cubic-bezier(0.2, 1, 0.2, 1);
	}

	.control-wrap {
		width: 20px;
		line-height: 20px;
		overflow: hidden;
		display: inline-block;
		vertical-align: top;
	}
	.controls .out-of,
	.controls .total {
		display: inline-block;
		font-size: 16px;
		line-height: 20px;
		color: #000;
		vertical-align: top;
	}
	.control-wrap {
		font-size: 16px;
	}
	.controls .out-of,
	.controls .total {
		width: 20px;
		text-align: center;
	}
	.controls .out-of {
		width: 13px;
		top: -1px;
		position: relative;
	}
	.control-wrap ul {
		width: auto;
		-ms-transition: transform 0.33s;
		-webkit-transition: transform 0.33s;
		transition: transform 0.33s;
	}
	.control-wrap ul li {
		color: #000;
		display: block;
		float: left;
		width: 20px;
		font-size: 16px;
		line-height: 20px;
		cursor: auto;
	}
	.controls {
		vertical-align: top;
	}
}

section.who-we-are-section {
	.col-lg-5 {
		padding: calc(100vw * 0.03);

		@media only screen and (min-width: 1425px) {
			& {
				padding: calc(1245px * 0.03);
			}
		}

		@media only screen and (min-width: 1000px) {
			& {
				padding: calc((100vw - 180px) * 0.03);
			}
		}
	}

	.btn {
		padding: 10px 24px;
		font-size: 12px;
	}

	img {
		max-width: 1245px;
		width: 100%;
	}
}

section.shop-section {
	.btn {
		padding: 10px 24px;
		font-size: 12px;
	}
}

section.trupanion-section {
	.col-lg-6:not(.logo) {
		padding: calc(100vw * 0.03);

		@media only screen and (min-width: 1425px) {
			& {
				padding: calc(1245px * 0.03);
			}
		}

		@media only screen and (min-width: 1000px) {
			& {
				padding: calc((100vw - 180px) * 0.03);
			}
		}
	}

	.col-lg-6 {
		padding: calc(100vw * 0.04);

		@media only screen and (min-width: 1425px) {
			& {
				padding: calc(1245px * 0.04);
			}
		}

		@media only screen and (min-width: 1000px) {
			& {
				padding: calc((100vw - 180px) * 0.04);
			}
		}
	}

	.btn {
		padding: 6px 14px;
		font-size: 12px;
		border: 2px solid rgba(255, 255, 255, 0.75);
		&:hover {
			background-color: #63543f;
			color: $white !important;
			border: 2px solid rgba(255, 255, 255, 1);
		}
	}
}

section.map-section {
	#addressMap {
		.shadow-1 {
			box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.10);
		}
		.btn-circle {
			height: 3rem;
			width: 3rem;
			line-height: 3rem;
			border-radius: 100% !important;
		}
		.address-map {
		  min-height: 350px;
	  
		  .google-maps {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			padding: 0;
		  }
	  
		  iframe {
			//height: 800px;
			//margin: -200px 0;
			min-height: 350px;
		  }
	  
		  @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
	  
			&,
			iframe {
			  min-height: 300px;
			}
		  }
		}

		.address-map {
			display: block;
		
			[class*="col"] {
			  display: block;
			  width: 100%;
			  padding: 0;
			}
		  }
	  }
	@include media-breakpoint-down(md) {
		#map {
			height: auto !important;
		}
		& {
			height: auto !important;
		}
	}

	.info-col {
		padding-left: 15px;
		padding-right: 15px;

		a {
			color: $secondary;
			margin-bottom: 15px;

			&:hover {
				color: #9b876d;
			}
		}

		@media only screen and (min-width: 1425px) {
			& {
				padding-left: calc(1245px * 0.07);
				// padding-right: calc(1245px * 0.07);
			}
		}

		@media only screen and (min-width: 1000px) {
			& {
				padding-left: calc((100vw - 180px) * 0.07);
				// padding-right: calc((100vw - 180px) * 0.07);
			}
		}
	}
}

.text-initial {
	text-transform: initial;
}

footer.foot {
    background-color: #252525;
    padding: 40px 0;
    
    p {
      font-size: 14px;
      color: #fff;
    }

    a {
      color: #cccccc;

      &:hover {
        color: #63543f;
      }
    }

    .info {
      .mod {
        p {
			font-size: 18px;
        	color: #fff;
		}
      }
    }

    .btm {
        color: #676B72;

        .social {
			a {
			color: #cccccc;

				&:hover {
					color: #63543f;
				}
			}
        }
    }
}

.nectar-social a:hover {
  box-shadow: 0 10px 24px rgba(0,0,0,0.15);
}
body[data-slide-out-widget-area-style="slide-out-from-right"] .nectar-social.fixed {
	transition: transform 0.45s cubic-bezier(.15,0.2,.1,1);
  }
  .ocm-effect-wrap.material-ocm-open .nectar-social.fixed {
	-webkit-transform: scale(0);
	transform: scale(0);
  }

  /*---------------------------------------------------------------------------

[Table Of Contents]

1. Hover style
2. Fixed style
3. Minimal style
4. Nectar Love

---------------------------------------------------------------------------*/

  
  /************ 2. Fixed style **************/
  .nectar-social.fixed > a:before {
	background-color: #63543f;
  }
  @media only screen and (max-width:999px){
	 .nectar-social.fixed >a{
		 transition:all 0.35s cubic-bezier(.15,0.2,.1,1);
		  -webkit-transition:all 0.35s cubic-bezier(.15,0.2,.1,1);
		  -webkit-transform:scale(0);
		  transform:scale(0)
	 }
	 .nectar-social.fixed{
		margin: 0;
		height:50px;
		pointer-events:none
	 }
	  .nectar-social.fixed.visible >a{
		 -webkit-transform:scale(1);
		  transform:scale(1)
	 }
	  .nectar-social.fixed.visible{
		 pointer-events:auto
	 }
  }
  
  .nectar-social.fixed {
	 position:fixed;
	  right:34px;
	  bottom:100px;
	  height:50px;
	  width:50px;
	  line-height:50px;
	  z-index:1000
  }
  .nectar-social.fixed >a {
	 height:50px;
	  width:50px;
	  line-height:52px;
	  text-align:center;
	  display:block;
	  position:absolute;
	  bottom:0;
	  left:0;
	  z-index:10;
  }
  .nectar-social.fixed >a:before{
	 display:block;
	  content:'';
	  position:absolute;
	  top:0;
	  left:0;
	  width:100%;
	  transition:all 0.45s cubic-bezier(.15,0.2,.1,1);
	  -webkit-transition:all 0.45s cubic-bezier(.15,0.2,.1,1);
	  height:100%;
	  transform: translateZ(0);
	  backface-visibility:hidden;
	  border-radius:50%
  }
  .nectar-social.fixed:hover >a:before{
	 box-shadow:0 7px 15px rgba(0,0,0,0.2)
  }
  
  .nectar-social.fixed >a >[class^="icon-"].icon-default-style{
	 font-size:16px;
	 height: 50px;
	 width: 50px;
	 line-height: 50px;
	  color:#fff;
	  left:-1px;
	  top: 0;
	  z-index: 10;
	  position: relative;
  }
  
  .nectar-social.fixed .nectar-social-inner {
	 position:absolute;
	  height:50px;
	  width:50px;
	  bottom:0;
	  left:0;
	  z-index:1;
	  text-align:center
  }
  .nectar-social.fixed .nectar-social-inner a{
	 display:block;
	  line-height:36px;
	  height:36px;
	  padding:0;
	  margin:0;
	  width:36px;
	  left:7px;
	  position:absolute;
	  opacity:0;
	  border:none;
	  top:0;
	  box-shadow:0 7px 15px rgba(0,0,0,0.2);
	  border-radius:50%;
	  -webkit-transition:all 0.45s cubic-bezier(.15,0.2,.1,1);
	  transition:all 0.45s cubic-bezier(.15,0.2,.1,1)
  }
  .nectar-social.fixed >a:after, 
  .nectar-social.fixed .nectar-social-inner a:after{
	 background-color:#fff;
	  position:absolute;
	  width:100%;
	  height:100%;
	  top:0;
	  left:0;
	  opacity:0;
	  content:'';
	  display:block;
	  border-radius:50%
  }
  .nectar-social.fixed a:after{
	 -webkit-transition:opacity 0.25s cubic-bezier(.15,0.2,.1,1);
	  transition:opacity 0.25s cubic-bezier(.15,0.2,.1,1)
  }
  @keyframes socialButtonFlash{
	 0%{
		 opacity:0;
		  transform:scale(1)
	 }
	  25%{
		 opacity:0.22;
		  transform:scale(1.1115)
	 }
	  100%{
		 opacity:0;
		  transform:scale(1.23)
	 }
  }
  .nectar-social.fixed:hover >a:after{
	 -webkit-animation:socialButtonFlash 0.45s cubic-bezier(.15,0.2,.1,1) forwards;
	  animation:socialButtonFlash 0.45s cubic-bezier(.15,0.2,.1,1) forwards
  }
  .nectar-social.fixed a:hover:after{
	 opacity:0.2
  }
  .nectar-social.fixed:hover >a:before{
	 -webkit-transform:scale(1.23) translateZ(0);
	  transform:scale(1.23) translateZ(0)
  }
  .nectar-social.fixed .nectar-social-inner a:nth-child(1){
	 transform:translateY(0px) scale(0);
	  -webkit-transform:translateY(0px) scale(0)
  }
  .nectar-social.fixed .nectar-social-inner a:nth-child(2){
	 -webkit-transform:translateY(-36px) scale(0);
	  transform:translateY(-36px) scale(0)
  }
  .nectar-social.fixed .nectar-social-inner a:nth-child(3){
	 -webkit-transform:translateY(-86px) scale(0);
	  transform:translateY(-86px) scale(0)
  }
  .nectar-social.fixed .nectar-social-inner a:nth-child(4){
	 -webkit-transform:translateY(-136px) scale(0);
	  transform:translateY(-136px) scale(0)
  }
  .nectar-social.fixed .nectar-social-inner a:nth-child(5){
	 -webkit-transform:translateY(-186px) scale(0);
	  transform:translateY(-186px) scale(0)
  }
  .nectar-social.fixed .nectar-social-inner a:nth-child(6){
	 -webkit-transform:translateY(-186px) scale(0);
	  transform:translateY(-236px) scale(0)
  }
  .nectar-social.fixed .nectar-social-inner a:nth-child(7){
	 -webkit-transform:translateY(-186px) scale(0);
	  transform:translateY(-286px) scale(0)
  }
  .nectar-social.fixed:hover .nectar-social-inner a:nth-child(1){
	 -webkit-transition:all 0.45s cubic-bezier(.15,0.2,.1,1);
	  -webkit-transform:translateY(-56px) scale(1);
	  transition:all 0.45s cubic-bezier(.15,0.2,.1,1);
	  transform:translateY(-56px) scale(1);
	  opacity:1
  }
  .nectar-social.fixed:hover .nectar-social-inner a:nth-child(2){
	 -webkit-transition:all 0.45s cubic-bezier(.15,0.2,.1,1);
	  -webkit-transform:translateY(-106px) scale(1);
	  transition:all 0.45s cubic-bezier(.15,0.2,.1,1);
	  transform:translateY(-106px) scale(1);
	  opacity:1
  }
  .nectar-social.fixed:hover .nectar-social-inner a:nth-child(3){
	 -webkit-transition:all 0.45s cubic-bezier(.15,0.2,.1,1);
	  -webkit-transform:translateY(-156px) scale(1);
	  transition:all 0.45s cubic-bezier(.15,0.2,.1,1);
	  transform:translateY(-156px) scale(1);
	  opacity:1
  }
  .nectar-social.fixed:hover .nectar-social-inner a:nth-child(4){
	 -webkit-transition:all 0.45s cubic-bezier(.15,0.2,.1,1);
	  -webkit-transform:translateY(-206px) scale(1);
	  transition:all 0.45s cubic-bezier(.15,0.2,.1,1);
	  transform:translateY(-206px) scale(1);
	  opacity:1
  }
  .nectar-social.fixed:hover .nectar-social-inner a:nth-child(5){
	 -webkit-transition:all 0.45s cubic-bezier(.15,0.2,.1,1);
	  -webkit-transform:translateY(-256px) scale(1);
	  transition:all 0.45s cubic-bezier(.15,0.2,.1,1);
	  transform:translateY(-256px) scale(1);
	  opacity:1
  }
  .nectar-social.fixed:hover .nectar-social-inner a:nth-child(6){
	 -webkit-transition:all 0.45s cubic-bezier(.15,0.2,.1,1);
	  -webkit-transform:translateY(-256px) scale(1);
	  transition:all 0.45s cubic-bezier(.15,0.2,.1,1);
	  transform:translateY(-306px) scale(1);
	  opacity:1
  }
  .nectar-social.fixed:hover .nectar-social-inner a:nth-child(7){
	 -webkit-transition:all 0.45s cubic-bezier(.15,0.2,.1,1);
	  -webkit-transform:translateY(-256px) scale(1);
	  transition:all 0.45s cubic-bezier(.15,0.2,.1,1);
	  transform:translateY(-356px) scale(1);
	  opacity:1
  }
  .nectar-social.fixed:hover{
	 height:340px
  }
  body .nectar-social.fixed a i,
  #ajax-content-wrap .nectar-social.fixed a i{
	  color:#fff;
	  position: relative;
	  font-size:16px;
  }

  .social-mod {
	a {
		&[href*="instagram"] {
			background: linear-gradient(to bottom,  rgba(79,91,213,1) 0%,rgba(150,47,191,1) 25%,rgba(214,41,118,1) 50%,rgba(250,126,30,1) 75%,rgba(254,218,117,1) 100%); 
		}
		&[href*="youtube"] {
			background-color: #FF0000 !important;
		}
		&[href*="facebook"] {
			background-color: #3c5a9a !important;
		}
		&[href*="twitter"] {
			background-color: #000000 !important;
		}
		&[href*="google"] {
			background-color: #dd4c3b !important;
		}
		&[href*="yelp"] {
			background-color: #b00606 !important;
		}
		&[href*="linkedin"] {
			background-color: #0078b5 !important;
		}
	}
  }
  

/* Shape divider layer */
.shape-divider-wrap {
	position: absolute;
	top: auto;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 150px;
	z-index: 3;
	transform: translateZ(0);
}
.post-area.span_9 .shape-divider-wrap {
	overflow: hidden;
}
.shape-divider-wrap[data-position="top"] {
	top: -1px;
	bottom: auto;
}
.shape-divider-wrap[data-position="top"] {
	transform: rotate(180deg);
}
.shape-divider-wrap[data-front="true"] {
	z-index: 50;
}

@media only screen and (min-width: 1000px) {
	.shape-divider-wrap[data-style="curve"] .shape-divider {
		filter: blur(0.5px);
		transform: scale(1.03);
	}
}

.shape-divider-wrap[data-style="waves_opacity"] svg path:first-child {
	opacity: 0.6;
}
@media only screen and (min-width: 1000px) {
	.shape-divider-wrap[data-style="clouds"] .shape-divider {
		min-width: 1700px;
	}
}
@media only screen and (max-width: 999px) {
	.shape-divider-wrap[data-style="clouds"] .shape-divider {
		min-width: 800px;
	}
}
@media only screen and (max-width: 690px) {
	.shape-divider-wrap[data-style="clouds"] .shape-divider {
		min-width: 690px;
	}
}
.shape-divider-wrap[data-style="fan"] svg {
	width: 102%;
	left: -1%;
}
.shape-divider-wrap[data-style="fan"] svg polygon:nth-child(2) {
	opacity: 0.15;
}
.shape-divider-wrap[data-style="fan"] svg rect {
	opacity: 0.3;
}
.shape-divider-wrap[data-style="mountains"] svg path:first-child {
	opacity: 0.1;
}
.shape-divider-wrap[data-style="mountains"] svg path:nth-child(2) {
	opacity: 0.12;
}
.shape-divider-wrap[data-style="mountains"] svg path:nth-child(3) {
	opacity: 0.18;
}
.shape-divider-wrap[data-style="mountains"] svg path:nth-child(4) {
	opacity: 0.33;
}
.shape-divider-wrap[data-style="curve_opacity"] svg path:nth-child(1),
.shape-divider-wrap[data-style="waves_opacity_alt"] svg path:nth-child(1) {
	opacity: 0.15;
}
.shape-divider-wrap[data-style="curve_opacity"] svg path:nth-child(2),
.shape-divider-wrap[data-style="waves_opacity_alt"] svg path:nth-child(2) {
	opacity: 0.3;
}
.shape-divider {
	width: 100%;
	left: 0;
	bottom: -1px;
	height: 100%;
	position: absolute;
}
.shape-divider-wrap.no-color .shape-divider {
	fill: #fff;
}
@media only screen and (max-width: 999px) {
	.shape-divider-wrap:not([data-using-percent-val="true"]) .shape-divider {
		height: 75%;
	}
	.shape-divider-wrap[data-style="clouds"]:not([data-using-percent-val="true"])
		.shape-divider {
		height: 55%;
	}
}
@media only screen and (max-width: 690px) {
	.shape-divider-wrap:not([data-using-percent-val="true"]) .shape-divider {
		height: 33%;
	}
	.shape-divider-wrap[data-style="clouds"]:not([data-using-percent-val="true"])
		.shape-divider {
		height: 33%;
	}
}

.row-bg.using-bg-color {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	-webkit-background-size: cover;
}

#to-top {
	display: block;
	position: fixed;
	text-align: center;
	line-height: 12px;
	right: 43px;
	bottom: -30px;
	color: #fff;
	cursor: pointer;
	border-radius: 100%;
	z-index: 9994;
	height: 29px;
	width: 29px;
	background-color: rgba(0, 0, 0, 0.25);
	background-repeat: no-repeat;
	background-position: center;
	transition: background-color 0.1s linear;
	-webkit-transition: background-color 0.1s linear;
	&:hover {
		background-color: $primary;
	}
	&:after {
		background-color: $primary !important;
	}
}

#to-top {
	transition: box-shadow 0.3s cubic-bezier(0.55, 0, 0.1, 1),
		background-color 0.1s linear;
	-webkit-transition: -webkit-box-shadow 0.3s cubic-bezier(0.55, 0, 0.1, 1),
		background-color 0.1s linear;
	background-color: rgba(0, 0, 0, 0.25);
}

#to-top:hover,
#to-top.dark:hover {
	transition: box-shadow 0.3s cubic-bezier(0.55, 0, 0.1, 1),
		background-color 0.05s linear 0.25s;
	-webkit-transition: -webkit-box-shadow 0.3s cubic-bezier(0.55, 0, 0.1, 1),
		background-color 0.05s linear 0.25s;
	box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.16);
	background-color: transparent !important;
}
#to-top:after,
#to-top:before {
	display: block;
	content: " ";
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	background-color: #000;
	transform: scale(0);
	-webkit-transform: scale(0);
	transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
	-webkit-transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
	border-radius: 100px;
	-webkit-border-radius: 100px;
}
#to-top:before {
	background-color: rgba(255, 255, 255, 0.25);
	transform: scale(1);
	-webkit-transform: scale(1);
	transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
	-webkit-transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
	opacity: 0;
	z-index: 2;
}

#to-top:hover:after {
	transform: scale(1);
	-webkit-transform: scale(1);
}
#to-top {
	overflow: hidden;
}

#to-top i.fa-angle-up.top-icon,
#to-top i.fa-angle-up {
	-webkit-transform: translate(0, 0px);
	transform: translate(0, 0px);
	transition: transform 0.2s ease;
	-webkit-transition: transform 0.2s ease;
}

#to-top:hover i.fa-angle-up.top-icon,
#to-top:hover i.fa-angle-up,
#to-top.hovered i.fa-angle-up.top-icon,
#to-top.hovered i.fa-angle-up {
	-webkit-transform: translate(0, -29px);
	transform: translate(0, -29px);
}

#to-top:active:before {
	opacity: 1;
}

#to-top i {
	line-height: 29px;
	width: 29px;
	height: 29px;
	font-size: 14px;
	top: 0;
	left: 0;
	text-align: center;
	position: relative;
	z-index: 10;
	background-color: transparent;
}
#to-top:hover,
#to-top.dark:hover {
	background-color: #000;
}
#to-top.dark {
	background-color: rgba(0, 0, 0, 0.87);
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}
input[type="search"]::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}

body.mobile-nav-show {
	.sticky-spacer {
		margin-top: 0px !important;
	}
}

#slide-out-widget-area .socials {
	a {
		overflow: hidden;
		width: 20px;
		height: 20px;
		&:hover {
			i {
				transform: translateY(-100%);
				color: $white !important;
			}
		}
		i {
			color: $white !important;
			width: 20px;
			height: 20px;
			line-height: 20px;
			transition: 0.3s ease;
		}
	}
}
body.page-type-36 {
	h3.title-header {
		display: inline-block;
		position: relative;
	}
	h3.title-header:before {
		content: "";
		position: absolute;
		width: 40px;
		height: 2px;
		background: #DAD7CD;

		left: 50%;
		transform: translate3d(-50%, 0, 0);
		bottom: -20px;
	}

	.item-text span.d-block:not(:first-of-type) {
		letter-spacing: 2px;
	}

	.img-col > img {
		width: 395px !important;
		height: auto !important;
		max-width: 100%;
	}

	.page-intro {
		margin-bottom: 80px;
	}
	.modal-content {
		min-height: 50vh;
	}
	.modal-dialog {
		max-width: 1200px !important;
	}

	.modal-backdrop.show {
		opacity: 0.5;
		visibility: visible;
	}

	hr.my-5 {
		display: none;
	}

	[id*="_DivContactPhoto"] {
		width: 395px !important;
		height: 420px !important;
		position: relative;
		max-width: 100% !important;
		~ div {
			display: none;
		}

		.item-text {
			pointer-events: none;
			padding: 0 20px;
			left: 50%;
			transform: translateX(-50%);
			bottom: 20px;
		}
	}

	[id*="_DivContactPhoto"].has-content {
		.panel-image:hover {
			cursor: pointer;
			> .panel-image-overlay {
				background-color: rgba(0, 0, 0, 0.4) !important;
			}
		}
	}
}
