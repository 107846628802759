$primary: #63543f !default;
$secondary: #9b876d !default;
$white: #fff !default;
$gray-100: #f8f8f8 !default;
$gray-200: #efefef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #888888 !default;
$gray-700: #676767 !default;
$gray-800: #444444 !default;
$gray-900: #252525 !default;
$black: #0d0d0d !default;

$theme-colors: (
	"tertiary": #DAD7CD,
	"red": #b20009,
	"darkblue": #133456,
	"new-color": #cc00ff,
	"new-gradient": #ff0055,
) !default;

[class*="new-gradient"] {
	background-image: linear-gradient(
		90deg,
		rgba(26, 215, 249, 0) 0%,
		#18d9f9 100%
	);
}

@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600&display=swap');

$body-bg: $white !default;
$body-color: #676767 !default;

$link-color: $primary !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 25%) !default;
$link-hover-decoration: underline !default;

$border-width: 1px !default;
$border-color: rgba($gray-600, 0.15) !default;

$border-radius: 4px !default;
$border-radius-lg: 4px !default;
$border-radius-sm: 4px !default;

$font-family-base: "Open Sans", sans-serif !default;
$headings-font-family: "Merriweather", serif !default;
$display-font-family: "Merriweather", serif !default;
$blockquote-font-family: "Open Sans", sans-serif !default;
$navbar-font-family: "Open Sans", sans-serif !default;
$navbar-brand-font-family: "Open Sans", sans-serif !default;
$btn-font-family: "Open Sans", sans-serif !default;

$font-size-base: 1.063rem !default; // Assumes the browser default, typically `16px`

$font-weight-base: 300 !default;
$line-height-base: 1.5 !default;

$h1-font-size: 45px !default;
$h2-font-size: 32px !default;
$h3-font-size: 30px !default;
$h4-font-size: 28px !default;
$h5-font-size: 25px !default;
$h6-font-size: 16px !default;

$h1-font-weight: 600 !default;
$h2-font-weight: 600 !default;
$h3-font-weight: 600 !default;
$h4-font-weight: 600 !default;
$h5-font-weight: 600 !default;
$h6-font-weight: 600 !default;

$headings-margin-bottom: 1rem !default;
$headings-line-height: 1.2 !default;
$headings-letter-spacing: 0px !default;
$headings-color: inherit !default;
$headings-text-transform: initial !default;
$headings-font-style: normal !default;

$display1-size: 60px !default;
$display2-size: 55px !default;
$display3-size: 40px !default;
$display4-size: 30px !default;
$display5-size: 24px !default;

$display1-weight: 600 !default;
$display2-weight: 600 !default;
$display3-weight: 600 !default;
$display4-weight: 600 !default;
$display-line-height: 1.1 !default;
$display-text-transform: initial !default;
$display-font-style: normal !default;

$lead-font-size: 20px !default;
$lead-font-weight: 300 !default;
$lead-line-height: 1.5 !default;

$small-font-size: 14px !default;
$extra-small-font-size: 12px !default;
$small-line-height: 1.4 !default;

$blockquote-font-size: 18px !default;
$blockquote-font-style: normal !default;
$blockquote-font-weight: 300 !default;
$blockquote-letter-spacing: 0 !default;
$blockquote-line-height: 1.8 !default;

$hr-border-color: $border-color !default;
$hr-border-width: 1px !default;

$backdrop-color: rgba($black, 0.75);

$label-font-size: 0.8rem;
$label-line-height: 1.2em;
$label-letter-spacing: 0;
$label-font-weight: bold;
$label-text-transform: initial;

$input-placeholder-color: rgba($gray-900, 0.7);
$input-border-color: $border-color;
$input-padding-y: 1rem;
$input-padding-x: 1.25rem;
$input-box-shadow: none;
$input-border-width: 1px;
$input-border-radius: $border-radius !default;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;

/*
 * 	Navigation Top Level Items
 */

$nav-font-size: 14px;
$nav-font-weight: 400;
$nav-font-style: normal;
$nav-text-transform: initial;
$nav-line-height: 1.4em;
$nav-letter-spacing: 0;
$nav-color: #999;
$nav-margin: 0 10px;
$nav-padding: 10px 0px;

$nav-hover-background: transparent;
$nav-hover-color: #999;
$nav-current-color: #999;
$nav-current-background: transparent;

/*
 * 	Navigation Drop Down Items
 */

$nav-dropdown-container-margin: 0 20px 0 0;
$nav-dropdown-container-padding-y: 0;
$nav-dropdown-container-padding-x: 0;
$nav-dropdown-item-padding-y: 15px;
$nav-dropdown-item-padding-x: 15px;
$nav-dropdown-background: $gray-200;
$nav-dropdown-border-radius: $border-radius;
$nav-dropdown-border: none;
$nav-dropdown-box-shadow: $box-shadow;
$nav-dropdown-container-padding: 15px 0px;
$nav-dropdown-item-padding: 10px 15px;
$nav-dropdown-font-family: $navbar-font-family;
$nav-dropdown-font-size: 14px;
$nav-dropdown-font-weight: 400;
$nav-dropdown-font-style: normal;
$nav-dropdown-text-transform: capitalize;
$nav-dropdown-line-height: 1.4em;
$nav-dropdown-letter-spacing: 0;
$nav-dropdown-color: #999;
$nav-dropdown-second-color: #999;
$nav-dropdown-third-color: #999;

$nav-dropdown-hover-background: $primary;
$nav-dropdown-hover-color: $white;
$nav-dropdown-current-background: $secondary;
$nav-dropdown-current-color: $primary;
$nav-dropdown-highlighted-color: $white;

/*
 * 	Mobile Navigation
 */

$mobile-nav-font-size: 12px;
$mobile-nav-line-height: 1.4em;
$mobile-nav-padding: 10px 15px;
$mobile-current-background: transparent;
$mobile-current-color: $gray-200;

/*
 * 	Buttons
 */

$btn-padding-y: 10px !default;
$btn-padding-x: 20px !default;
$btn-font-size: 14px !default;
$btn-text-transform: initial !default;
$btn-letter-spacing: 0em !default;
$btn-line-height: 1.4em !default;
$btn-border-width: 2px !default;
$btn-font-weight: 600 !default;
$btn-box-shadow: none !default;
$btn-focus-width: 1px !default;
$btn-focus-box-shadow: none !default;
$btn-active-box-shadow: none !default;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
	border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;
$yiq-contrasted-threshold: 175 !default;

$btn-padding-y-lg: $btn-padding-y * 1.1 !default;
$btn-padding-x-lg: $btn-padding-x * 1.1 !default;
$btn-font-size-lg: $btn-font-size !default;

$btn-padding-y-sm: $btn-padding-y * 0.9 !default;
$btn-padding-x-sm: $btn-padding-x * 0.9 !default;
$btn-font-size-sm: $btn-font-size !default;

/*
 * 	Badges
 */

$badge-padding-y: 0 !default;
$badge-padding-x: 0 !default;
$badge-font-size: 12px !default;
$badge-font-style: normal !default;
$badge-color: $gray-900 !default;
$badge-font-weight: 700 !default;
$badge-text-transform: uppercase !default;
$badge-line-height: 1.4em !default;
$badge-letter-spacing: 0.1em !default;
$badge-border-radius: 0 !default;
$badge-background-color: transparent !default;
$badge-border-width: 0 !default;
$badge-border-color: transparent !default;

$badge-focus-width: 1px !default;

/*
 * 	Default theme - Owl Carousel CSS File
 */

$owl-color-base: theme-color("primary") !default;
$owl-color-white: $white !default;
$owl-color-gray: $gray-500 !default;

//nav

$owl-nav-color: rgba($white, 0.75) !default;
$owl-nav-color-hover: $white !default;
$owl-nav-font-size: 40px !default;
$owl-nav-rounded: 0 !default;
$owl-nav-margin: 0 10px !default;
$owl-nav-padding: 10px !default;
$owl-nav-background: transparent !default;
$owl-nav-background-hover: transparent !default;
$owl-nav-disabled-opacity: 0.5 !default;

//dots

$owl-dot-width: 10px !default;
$owl-dot-height: 10px !default;
$owl-dot-rounded: 100% !default;
$owl-dot-margin: 5px 7px !default;
$owl-dot-background: rgba($white, 0.5) !default;
$owl-dot-background-active: $white !default;
